window.addEventListener('load', function () {
    var professionalResponsibility = new Vue({
        el: '#formProfessionalResponsibility',
        data: {
            sent: false,
            errors: '',
            formName: {
                name: 'formProfessionalResponsibility',
                description: 'Заявка на страхование профессиональной ответственности'
            },
            form: {
                organizationName: {
                    "f-label": "Наименование компании *", 
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": true,
                    "f-style": {}
                },
                delegateName: {
                    "f-label": "ФИО представителя",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                phone: {
                    "f-label": "Введите номер телефона для обратной связи *",
                    "f-type": "phone",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": true,
                    "f-style": {},
                    "f-regexp": "^\\+7\\(\\d{3}\\) \\d{3}-\\d{2}-\\d{2}$"
                },
                email: {
                    "f-label": "Введите эл.почту для обратной связи *",
                    "f-type": "email",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": true,
                    "f-style": {}
                },
                businessType: {
                    "f-label": "Вид деятельности Вашей компании",
                    "f-type": "select",
                    "f-condition": true,
                    "f-value": "",
                    "f-dic": "businessTypeDic",
                    "f-plain-value" : "",
                    "f-required": false,
                    "f-style": {}
                },
                businessTypeComment: {
                    "f-label": "Дополнительная информация",
                    "f-type": "text",
                    "f-condition": false,
                    "f-value": "",
                    "f-placeholder": "Добавьте дополнительное описание при необходимости...",
                    "f-required": false,
                    "f-style": {}
                },
                insuranceContractNum: {
                    "f-label": "Номер договора страхования *",
                    "f-type": "text",
                    "f-condition": false,
                    "f-value": "",
                    "f-placeholder": "Начинается с СМО или ИНВ",
                    "f-required": true,
                    "f-style": {},
                    "f-regexp": "^СМО|ИНВ.*"
                },
                insuranceContractLabel: {
                    "f-type": "label",
                    "f-condition": false,
                    "f-value": "ООО СК «Паритет-СК» в настоящий момент принимает на страхование ответственность только тех управляющих арбитражных, которые уже имеют договоры страхования с нашей компанией.",
                    "f-style": {},
                    "f-style-def": {
                        fontSize: '0.7em',
                        width: '80%'
                    }
                },
                limitLiability: {
                    "f-label": "Лимит ответственности (млн.рублей)",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": false,
                    "f-style": {}
                },
                location: {
                    "f-label": "Адрес регистрации (город) компании (ИП)",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                companyAge: {
                    "f-label": "Сколько лет Ваша компания осуществляет свою деятельность",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                }
            },
            dictionaries: {
                businessTypeDic: [
                    {name: "notary", description: "нотариальная деятельность"},
                    {name: "airport", description: "владельцы аэропортов или органов обслуживания воздушного движения"},
                    {name: "nuclear", description: "производственная деятельность, связанная с объектами использования атомной энергии"},
                    {name: "aircraft-worker", description: "эксплуатанты воздушных судов при авиационных работах"},
                    {name: "forwarder", description: "перевозчики и экспедиторы"},
                    {name: "arbitrage", description: "арбитражные управляющие"},
                    {name: "sailing", description: "владельцы средств водного транспорта"},
                    {name: "trader", description: "операторы электронных площадок и организаторы торгов"},
                    {name: "aircrafr-owner", description: "владельцы средств воздушного транспорта"},
                    {name: "other", description: "иное"}
                ]
            }
        },
        methods: {
            refreshForm() {
                for (var key in this.form) {
                    this.form[key]['f-value'] = '';
                }
            },
            onSubmit(evt) {
                evt.preventDefault();
                
                this.errors = "";
                let errorsCnt = validateForm(this.form);
                if (errorsCnt > 0) {
                    this.errors = "Необходимо корректно заполнить все поля, отмеченные звездочкой (*)";
                }

                if (errorsCnt == 0) {
                
                    let mailMessage = constructMailMessage(this.form, this.formName.description);

                    const emailOptions = {
                        method: 'POST',
                        headers: { 'content-type': 'application/json' },
                        data: {msg: mailMessage},
                        url: '/local/form/submit-msg-form.php',
                    };

                    this.$axios(emailOptions)
                        .then(res => { 
                            // console.log('msg was sent ' + mailMessage);
                            this.sent = true; 
                            this.refreshForm();
                            $.fancybox.close({src: '#' + this.form.name});
                            $.fancybox.open('<div  id="thanks" class="popup ths"> <div class="ths-box"> <div class="ths-marg"> <p class="ths-t1">заявка принята</p> <p class="ths-t2">Мы свяжемся с вами <br>в ближайшее время</p> </div> </div> </div>');
                        })
                        .catch(function (error) {
                            console.log(error);
                            $.fancybox.open('<div class="message"><h2>Ошибка</h2></div>');
                        });
                }
            }            
        },
        computed: {
            watchBusinessType() {
                return this.form.businessType['f-value'];
            }
        },
        watch: {
            watchBusinessType: function() {
                var vm = this;
                
                try {
                    this.form.businessTypeComment['f-value'] = '';
                    this.form.businessTypeComment['f-condition'] = this.form.businessType['f-value'] === 'other';

                    this.form.insuranceContractNum['f-value'] = '';
                    this.form.insuranceContractNum['f-condition'] = this.form.businessType['f-value'] === 'arbitrage';
                    this.form.insuranceContractLabel['f-condition'] = this.form.businessType['f-value'] === 'arbitrage';

                    this.form.businessType['f-plain-value'] = this.dictionaries[this.form.businessType['f-dic']] ? 
                        this.dictionaries[this.form.businessType['f-dic']].find(function(dic){
                            return dic.name === vm.form.businessType['f-value'] ;
                        }).description : '';
                } catch(ignoredException) {}
            }
        }
    });
})